import React from 'react'
import './Home.css'
import HeroImg from '../../constants/images/hero-img.png'
import StartButton from '../../constants/styles/buttons/startbutton/StartButton'

type Props = {}

const Home : React.FC = () => {
  return (
    <div className='container'>
       <div className='row'>
        <div style={{paddingTop:'70px'}}  className="col-1_2 ">
            <h1 className='home-title'>
                TempFab'e Hoşgeldiniz
            </h1>
            <p className='home-desc'>Web Sitesi yaparken CSS ile uğraşmak istemiyormusun ? o zaman TempFab tam sana göre !! Buradan hazır template alabilirsin.</p>
    <StartButton/>
        </div>
        <div  className="col-1_2 p-0">
           <img className='hero-img'  src={HeroImg} height={300}/>
        </div>

       </div>
       <h3  className='service-title'>Servislerimiz</h3>
<div className="row">
  <div className="col-1_3">
    <div className="card">
      <h3 className='cart-title'>Card 1</h3>
      <p className='card-paragraph'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit, neque aut quam numquam nisi, odio quaerat, voluptatem optio ad in similique autem ducimus fugit magni fugiat. Ex quia fuga quaerat corrupti ea reiciendis magni? Distinctio, doloremque aperiam error explicabo commodi asperiores dolore deleniti reiciendis quas eveniet voluptatum aliquam perspiciatis? Quasi perferendis non ut blanditiis consequatur pariatur alias omnis nulla veniam nesciunt labore unde debitis quis quisquam corporis, voluptas consectetur, eum inventore sit architecto laboriosam maiores natus dolorum possimus. Voluptas, culpa? Minus obcaecati debitis error nemo omnis dolor non, ipsum dicta velit dignissimos quasi perferendis? Numquam atque laborum cumque perferendis fuga!</p>
    </div>
  </div>
  <div className="col-1_3">
  <div className="card">
      <h3 className='cart-title'>Card 1</h3>
      <p className='card-paragraph'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit, neque aut quam numquam nisi, odio quaerat, voluptatem optio ad in similique autem ducimus fugit magni fugiat. Ex quia fuga quaerat corrupti ea reiciendis magni? Distinctio, doloremque aperiam error explicabo commodi asperiores dolore deleniti reiciendis quas eveniet voluptatum aliquam perspiciatis? Quasi perferendis non ut blanditiis consequatur pariatur alias omnis nulla veniam nesciunt labore unde debitis quis quisquam corporis, voluptas consectetur, eum inventore sit architecto laboriosam maiores natus dolorum possimus. Voluptas, culpa? Minus obcaecati debitis error nemo omnis dolor non, ipsum dicta velit dignissimos quasi perferendis? Numquam atque laborum cumque perferendis fuga!</p>
    </div>
  </div>
  <div className="col-1_3">
  <div className="card">
      <h3 className='cart-title'>Card 1</h3>
      <p className='card-paragraph'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit, neque aut quam numquam nisi, odio quaerat, voluptatem optio ad in similique autem ducimus fugit magni fugiat. Ex quia fuga quaerat corrupti ea reiciendis magni? Distinctio, doloremque aperiam error explicabo commodi asperiores dolore deleniti reiciendis quas eveniet voluptatum aliquam perspiciatis? Quasi perferendis non ut blanditiis consequatur pariatur alias omnis nulla veniam nesciunt labore unde debitis quis quisquam corporis, voluptas consectetur, eum inventore sit architecto laboriosam maiores natus dolorum possimus. Voluptas, culpa? Minus obcaecati debitis error nemo omnis dolor non, ipsum dicta velit dignissimos quasi perferendis? Numquam atque laborum cumque perferendis fuga!</p>
    </div>
  </div>
</div>


    </div>
  )
}

export default Home