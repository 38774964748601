import React from 'react'
import { ClipLoader } from 'react-spinners'
import './Loading.scss';


type Props = {
  loading:boolean;
}

const Loading : React.FC<Props>= ({loading} )  => {
  return (
 
    <div className={loading ? 'loading' : 'd-none'}>
      <div className='loading-spin'>
    <ClipLoader
        
        size={100}
        color='green'
      />
      </div>
    </div>

  )
}

export default Loading