import React, { ChangeEventHandler } from 'react'
import './searchbar.css';
import {FaSearch} from 'react-icons/fa';
type Props = {
  value:string;
  onChange:ChangeEventHandler<HTMLInputElement>
}

const Searchbar = ({value,onChange}: Props) => {

  return (
 
<div className='search-bar-wrapper'>
    <input  type='text' onChange={onChange}  placeholder='Arama Yap' className='search-bar' />
    <button  className='search-bar-btn'> <FaSearch  color='#fff' size={15}/></button>
    </div>
  )
}

export default Searchbar