

export interface Route {
    id:number;
    name:string;
    navigateTo:string;

}



export const headerRoutes : Route[] = [
    { 
      id:1,
      name:'Ana Sayfa',
      navigateTo:'/'
    },
 
    {
        id:3,
        name:'Ürünlerimiz',
        navigateTo:'/products'
    },
    {
        id:4,
        name:'Hesabım',
        navigateTo:'/account/login'
    }
]