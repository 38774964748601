import React, { FormEvent, useEffect, useState } from 'react'
import FormInput from '../../../constants/styles/inputs/forminput/FormInput'
import SubmitButton from '../../../constants/styles/buttons/submitbutton/SubmitButton'
import './Login.scss';
import Modal from '../../../components/modal/Modal';
import AuthService from '../../../services/AuthService';
import { User } from '../../../features/User';
import Loading from '../../../components/loading/Loading';
import {IoMdCloseCircle} from 'react-icons/io';
type Props = {}

const Login = (props: Props) => {
const [email,setEmail] = useState<string>('');
const [password,setPassword] = useState<string>('');
const [error,setError] = useState<boolean>(false);
const [message,setMessage] = useState<string>('');

const [loading,setLoading] = useState<boolean>(false);
  const handleLogin =  async (e : FormEvent) => {
    setLoading(true);
    e.preventDefault();
const user  : User = {email,password};

await AuthService.Login(user).then(() => {
window.location.href = '/'
}).catch((e) => {
  setError(true)
  setLoading(false);
setMessage(e.message);
});

setLoading(false);



  }



  return (
    <div className='container'> 
    <div className='Login-form'>
<form  className='form' onSubmit={handleLogin}>
<h2 className='form-title'>Giriş Yap</h2>
<div className='row' >

    <div className='col-full'>
    <FormInput placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)}  />
    </div>
    <div className='col-full'>
    <FormInput type='password' placeholder='Şifre' value={password}   onChange={(e) => setPassword(e.target.value)} />
    </div>
    <div className='col-full'>
    <SubmitButton name='Giriş Yap' />
    </div>
 
</div>

<span style={{textAlign:'center',display:'block',fontSize:'12px',padding:'10px'}}>Hesabın yok mu ? <a style={{color:'#3DAC78'}} href="/account/register">Kayıt ol</a> </span>


<div className="col-full p-0">
<div className="error-container" style={{display: error ? 'block' :'none'}}>
  <IoMdCloseCircle size={20} color='#721c24' style={{marginRight:'10',transform:'translateY(4px)'}}/>
  <span className='error-message'>{message}</span>
</div>

</div>
</form>

</div>
<Loading  loading={loading}/>
    </div>
  )
}

export default Login