import { createSlice ,PayloadAction} from "@reduxjs/toolkit";
import Cookies from "js-cookie";





interface TokenState  {
    token:string | undefined;
    isAuthenticated:boolean;
}



const initialState : TokenState = {
  token : Cookies.get('token'),
  isAuthenticated: Cookies.get('token') ? true : false
}






const tokenReducer = createSlice({
    name: 'token',
   initialState,
   reducers:{
    setToken:(state,action : PayloadAction<string>)=>{
         Cookies.set('token',action.payload,{expires:1 / 1440}) 
      return {...state,token:action.payload,isAuthenticated:true};
    } ,

    removeToken:(state)=>{

Cookies.remove('token');
return {...state,isAuthenticated:false,token:undefined} ;
       

    }



   }
})
export const {setToken,removeToken} = tokenReducer.actions
export default tokenReducer.reducer;