import React, { ChangeEvent, useEffect, useState } from 'react'
import './Header.scss';
import Searchbar from '../../constants/styles/inputs/searchbar/Searchbar';

import { FaShoppingCart } from 'react-icons/fa';
import UserButton from '../../constants/styles/buttons/userbutton/UserButton';
import { Route, headerRoutes } from '../../constants/data/data';
import {IoMdClose} from 'react-icons/io';
import {HiMiniBars3} from 'react-icons/hi2';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { removeToken } from '../../storage/slices/tokenReducer';
import { RootState } from '../../storage';
import AuthService from '../../services/AuthService';





const Header : React.FC = () => {
  const [search,setSearch] = useState<string>('');
   const [drawer,setDrawer] = useState<boolean>(false);
const userState = useSelector((state : RootState) => state.tokenReducer);
  const handleInputChange = (e : ChangeEvent<HTMLInputElement>) => {
  setSearch(e.target.value)
  }

const dispatch = useDispatch();
  
const Logout = () => {
dispatch(removeToken());
window.location.href = '/';
}


useEffect(()=>{

const checkAuth = async () => {
if(userState.isAuthenticated){


  const auth = await AuthService.getUser(userState.token);
  if(auth === null){
       dispatch(removeToken());
  }

}
}
checkAuth();

},[]);



  return (
    <>
    <div className='header'>

      <div className='header-content'>
        <div className='only-mobile'>

          <HiMiniBars3 onClick={()=>setDrawer(true)} style={{cursor:'pointer'}} color='#fff' size={25}/>
       
         
        </div>




      <div className='web-tablets'>
<h1 className='header-title-first-part'>Temp</h1>
<h1 className='header-title-second-part'>Fab</h1>
      </div>
<div className='web'>
<Searchbar   value={search} onChange={handleInputChange}/>
</div>
<div>


<div className='header-icons-wrapper'>
  <div className='shopping-cart-wrapper'>
  <a href="/">
  <FaShoppingCart size={25} color='#fff'/>
  <div className='total-item-wrapper'>
    <span className='total-item'>1</span>
  </div>
  
  </a>
  </div>
  <div className='web'>
  <div className='account-wrapper'>
    {
      !userState.isAuthenticated ? <>
      <UserButton swipeLeft={true} color='#9932CC' name='Giriş Yap' onClick={()=>window.location.href = '/account/login'}/>
<UserButton swipeLeft={false} color='#0000FF' name='Kayıt ol' onClick={()=>window.location.href = '/account/register'} />
      </> :
      <UserButton swipeLeft={true} color='orange' name='Çıkış Yap' onClick={Logout}/>
    }



</div>
</div>  
</div>
</div>
 </div>
<div className='mobile'>
<Searchbar  value={search} onChange={handleInputChange}/>
</div>
<div className='mobile'>
  <div className='account-wrapper'>
<UserButton swipeLeft={true}  color='#9932CC' name='Giriş Yap' onClick={()=>window.location.href = '/account/login'}/>
<UserButton swipeLeft={false} color='#0000FF' name='Kayıt ol' onClick={()=>window.location.href = '/account/register'} />


</div>
</div>

    </div>
    <div className='mobile'>
  <div className='drawer-container' onClick={()=>setDrawer(false)} id={drawer ? 'opened-container' : 'closed-container'}/>
   <div className='drawer-menu' id={drawer ? 'open' : 'close'} >
    <div className='drawer-top'>
    <div>
  <h1 className='header-title-first-part'>Temp</h1>
<h1 className='header-title-second-part'>Fab</h1>
</div>
<span className='user-email'>
  Ozbay.yusuf02@gmail.com
</span>
<div onClick={()=>setDrawer(false)} className='drawer-close-btn'>
   <IoMdClose size={30} color='#ffffff'/>
</div>
    </div>
<ul>
  {
    headerRoutes.map((route : Route) => <li className='drawer-item'><a className='drawer-link' href={route.navigateTo}>{route.name}</a></li>)
  }
</ul>
   </div>
  
</div>

    </>
  )
}

export default Header