import React, { ChangeEventHandler } from 'react'
import './FormInput.scss';
type Props = {
value?:string;
onChange?:ChangeEventHandler<HTMLInputElement>
placeholder?:string;
type?:string;
    
}




const FormInput = ({type,value,onChange,placeholder}: Props) => {


    return <input  placeholder={placeholder} className='form-input' type={type ?? 'text'} value={value} onChange={onChange}/>
}

export default FormInput