import React, { useEffect, useState } from 'react'
import './UserInfo.scss';
import AuthService from '../../../services/AuthService';
import { User } from '../../../features/User';
import { ClipLoader } from 'react-spinners';
import Login from '../login/Login';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../storage';
import { removeToken } from '../../../storage/slices/tokenReducer';

type Props = {}

const UserInfo = (props: Props) => {

  const [user,setUser] = useState<User>();
  const [loading,setLoading] = useState<boolean>(false);
  const userState = useSelector((state : RootState) => state.tokenReducer);
  const dispatch = useDispatch();
  useEffect(() => {
  
  setLoading(true);
    const getAuthenticatedUser   = async () => {
  try {
      const token =userState.token;
      console.log(token);
   const authenticated_user = await AuthService.getUser(token!);
   if(authenticated_user != null){
    setUser(authenticated_user);

   }
   else {

dispatch(removeToken());

   }
  
   setLoading(false);
  }
  catch(e) {
    
    console.log("Error occured")
  }
}  

getAuthenticatedUser();

}, []);

if(!user){
  return loading ? <div>loading...</div> : <Login/> 

}


  
  return (
    !loading ? 
    <div className='user-info'>
          <div className="info-card">
            <span className='email-key'>Name</span>
            <span className='email-value'>{user.name}</span>
        </div>
        <div className="info-card">
            <span className='email-key'>Surname</span>
            <span className='email-value'>{user.surname}</span>
        </div>
        <div className="info-card">
            <span className='email-key'>Email</span>
            <span className='email-value'>{user.email}</span>
        </div>
    </div> : <div  style={{width:'100%',height:'800px',display:'flex',justifyContent:'center',alignItems:'center'}}> <ClipLoader
        
        size={100}
        color='green'
      /></div>
  )
}

export default UserInfo