import React from 'react'

type Props = {
    name:string;
    onClick:()=>void;
    color:string;
    swipeLeft:boolean;
}

const UserButton = ({swipeLeft,name,onClick,color} : Props) => {
  return <button onClick={onClick}  style={{cursor:'pointer',
  marginRight:swipeLeft ? 5 : 0
  ,backgroundColor:color,
  padding:'12px 25px',
  color:'#fff',
  border:0,
  fontWeight:'bold',
  borderRadius:5,
  letterSpacing:0.5}}>
   {name}
  </button>
}
export default UserButton;