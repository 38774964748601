import React from 'react'
import {MdOutlineMail} from 'react-icons/md';

import {FaInstagram, FaLinkedinIn, FaPhone} from 'react-icons/fa';
import './Footer.scss';

const Footer = () => {
  return (
    <div>
    <div className='footer'>
      <div className='row justify-content-space-between align-center'>
         <div className="email-info">
          <a href="mailto:Ozbay.yusuf02@gmail.com"><span className='email'> Ozbay.yusuf02@gmail.com</span></a>

         </div>
         <div className='footer-icons'>
          <a href="https://linkedin.com/in/yusuf-özbay-450749223">
<div className='footer-icon-item' >
<FaLinkedinIn color='#fff' size={20}/>
</div>
</a>
<a href="telno:05511267073">
<div className='footer-icon-item' >
<FaPhone color='#fff' size={20}/>
</div>
</a>

 </div>
      </div>
      
    
      </div>
      <div className="footer-bottom">
        <span className='footer-copyright-text'>© Copyright TempFab All Rights Reserved</span>
        <span className='author'>Designed By <a className='author-link'  rel="noreferrer" target='_blank' href="https://linkedin.com/in/yusuf-özbay-450749223">Yusuf Özbay</a></span>

      </div>
      
      </div>
  )
}

export default Footer