
import { configureStore } from "@reduxjs/toolkit";
import tokenReducer from "./slices/tokenReducer";



export const store = configureStore({
    reducer : {
        tokenReducer
    }
})
export default store;
export type RootState = ReturnType<typeof store.getState>;