import React from 'react'
import { headerRoutes } from '../../constants/data/data'
import HeaderRouteItem from '../../components/HeaderRouteItem/HeaderRouteItem'
import './HeaderBottom.scss';
type Props = {}

const HeaderBottom = (props: Props) => {
  return (
    <div className="header-bottom">
    {
          headerRoutes.map((route) => <HeaderRouteItem key={route.id} route={route} />)
         }  
        </div>
  )
}

export default HeaderBottom