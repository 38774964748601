import React from 'react'
import './SubmitButton.scss';
type Props = {
name:string;
}

const SubmitButton = ({name}: Props) => {
  return <button className='submit-btn'>{name}</button>
}

export default SubmitButton