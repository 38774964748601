import React, { useState } from 'react'
import './Modal.scss';
type Props = {
message:string;
title:string;
visible:boolean;
setVisible:React.Dispatch<React.SetStateAction<boolean>>;
}

const Modal : React.FC<Props> = ({setVisible,message,title,visible}) => {
;
  return (
    <div className='modal-container' style={{display:visible ? 'block' : 'none'}}>
  
        <div className="modal">
        <h3 className='modal-title'>Doğrulama Kodu</h3>
            <p className='modal-message'>{message}</p>
            <div style={{display:'flex',justifyContent:'end'}}>
            <button className='modal-btn' onClick={()=>{
setVisible(false);
            }}>Tamam</button>
            </div>
            </div>
    </div>
  )
}

export default Modal