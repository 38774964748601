import React, { FormEvent, useState } from 'react'
import './Register.scss';
import FormInput from '../../../constants/styles/inputs/forminput/FormInput';
import SubmitButton from '../../../constants/styles/buttons/submitbutton/SubmitButton';
import Loading from '../../../components/loading/Loading';
import AuthService from '../../../services/AuthService';
import { User } from '../../../features/User';
import {IoMdCloseCircle} from 'react-icons/io';
import Modal from '../../../components/modal/Modal';

const Register :React.FC= () => {
const [loading,setLoading] = useState<boolean>(false);
const [name,setName] = useState<string>('');
const [surname,setSurname] = useState<string>('');
const [email,setEmail] = useState<string>('');
const [password,setPassword] = useState<string>('');
const [error,setError] = useState<boolean>(false);
const [message,setMessage] = useState<string>('');
const [modal,setModal] = useState<boolean>(false);
 const handleRegister = async ( e : FormEvent) => {
  setLoading(true)
e.preventDefault();
const user : User = {
  name,
  surname,
  email,
  password
}


 await AuthService.Register(user).then(() => {

setModal(true);
setError(false);

 }).catch((e) => {
  setError(true)
  setLoading(false);
setMessage(e.message); 
 });

 setLoading(false);

}

  return (
 
    <div className='container'> 
    <div className='Register-form'>
<form onSubmit={handleRegister}  className='form'>
<h2 className='form-title'>Kayıt ol</h2>
<div className='row' >
    <div className='col-1_2 p-0'>
    <FormInput onChange={(e) => setName(e.target.value)} placeholder='Ad'  />
    </div>
    <div className='col-1_2 p-0'>
    <FormInput  onChange={(e) => setSurname(e.target.value)}  placeholder='Soyad' />
    </div>
    <div className='col-full'>
    <FormInput  onChange={(e) => setEmail(e.target.value)}  placeholder='Email' />
    </div>
    <div className='col-full'>
    <FormInput type='password'  onChange={(e) => setPassword(e.target.value)}  placeholder='Şifre' />
    </div>
    <div className='col-full'>
    <SubmitButton name='Kayıt ol' />
    </div>
 
</div>

<span style={{textAlign:'center',display:'block',fontSize:'12px',padding:'10px'}}>Hesabın varsa <a style={{color:'#3DAC78'}} href="/account/login"> Giriş Yap</a> </span>

<div className="col-full p-0">
<div className="error-container" style={{display: error ? 'block' :'none'}}>
  <IoMdCloseCircle size={20} color='#721c24' style={{marginRight:'10',transform:'translateY(4px)'}}/>
  <span className='error-message'>{message}</span>
</div>

</div>
</form>

</div>
<Loading loading={loading}/>
{
  <Modal setVisible={setModal} title='Doğrulama kodu' visible={modal} message={'Emailinize bir doğrulama linki gönderdik. Lütfen linke tıklayın.'}/>
}

    </div>

  )
}

export default Register