import React from 'react'
import'./Products.css';


const Divider = () => {
  return <div className='divider'/>
}

const  Products = () => {
  return (
    <div className='all-product-container'>
      <div className='container'>
      <h3 className='product-title'> Ürünler</h3>
<Divider/>
      </div>
    <div className=''>
        <h3 className=''> No Product Found !</h3> 

      
    </div>

    </div>
  )
}

export default Products