
import { BrowserRouter as Router,Route, Routes } from 'react-router-dom';
import './App.css';
import HeaderBottom from './includes/HeaderBottom/HeaderBottom';
import Footer from './includes/footer/Footer';
import Header from './includes/header/Header';
import Home from './pages/home/Home';
import Products from './pages/products/Products';
import Register from './pages/account/register/Register';
import Login from './pages/account/login/Login';
import UserInfo from './pages/account/userinfo/UserInfo';

import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './storage';


;



function App () {
const [auth,setAuth] = useState<boolean>(false);

const userState = useSelector((state : RootState )  => state.tokenReducer);
console.log(userState.isAuthenticated);
const dispatch = useDispatch();









  return  <div className="App" style={{backgroundColor:'#f0f0f0'}}> 
  
<Header />
<HeaderBottom/>
<Router>
  <Routes>
    <Route element={<Home/>}  path='/' />
    <Route element={<Products/>} path='/products' />
   <Route element={<Register/>} path='/account/register'/>
   <Route element={<UserInfo/>} path='/account/login'/>


  </Routes>
</Router>


    <Footer/>
 
 
    </div> 


  
}

export default App;