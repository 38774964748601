
import { baseUrl } from "../constants/data/url";
import { User } from "../features/User"
import store from "../storage";
import { removeToken, setToken } from "../storage/slices/tokenReducer";






const Register =  async (user: User) => {

 const response =   await fetch( baseUrl+'/api/user',{
method:'POST',    
body:JSON.stringify(user),
headers : {
    'Content-Type':'application/json',
    'templenest-secure-apikey':'9b2e9a99983cec7ce516ed1f104ea299e60439b95f1f556834ec0af87bbcb26286ff0a9879e8bb0c7a307842deee2552f88f0a35f31d9acf2f75923dac4fe61b'



}


});
const json = await response.json();
if(response.ok){
    console.log(json)
return;
}
throw new Error(json);
}
const CheckUser = async  (token? : string) => {
    
    const response = await fetch( baseUrl+'/api/user/check',{
        headers : {
            'Authorization':`Bearer ${token ?? ''}`,
            'templenest-secure-apikey':'9b2e9a99983cec7ce516ed1f104ea299e60439b95f1f556834ec0af87bbcb26286ff0a9879e8bb0c7a307842deee2552f88f0a35f31d9acf2f75923dac4fe61b'
        }
    })


    if(response.status < 400){
   const json = await response.json();
            console.log(json);
        return true;
    }
  return false;


}
const Login = async (user : User) => {
    const credentials : User = {
        email:user.email,
        password:user.password
    }
const response = await fetch(baseUrl + '/api/user/login' ,{
method:'POST',    
body:JSON.stringify(credentials),
headers : {
    'Content-Type':'application/json',
    'templenest-secure-apikey':'9b2e9a99983cec7ce516ed1f104ea299e60439b95f1f556834ec0af87bbcb26286ff0a9879e8bb0c7a307842deee2552f88f0a35f31d9acf2f75923dac4fe61b'



}});
const json = await response.json() ;
if(response.ok){
   store.dispatch(setToken(json.authToken));

return;
}
else {
    throw new Error(json);
}

}

const getUser  = async (token? : string) => {
    const response = await fetch(baseUrl+'/api/user/profile',{
        headers : {
            'Authorization':`Bearer ${token}`,
            'templenest-secure-apikey':'9b2e9a99983cec7ce516ed1f104ea299e60439b95f1f556834ec0af87bbcb26286ff0a9879e8bb0c7a307842deee2552f88f0a35f31d9acf2f75923dac4fe61b'
        }
    });
  if(response.status === 401){
store.dispatch(removeToken());
   console.log("ewqewqww")
  }
    if(response.ok){
const json = await response.json();
return json;
    }
    return null;
}








const AuthService = {Register,CheckUser,Login,getUser};

export default AuthService;