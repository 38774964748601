import React from 'react'
import { Route } from '../../constants/data/data'
import './HeaderRouteItem.scss';
type Props = {
    route : Route
}

const HeaderRouteItem = ({route}: Props) => {
  return (
    <a  className='header-route-item'
    style={{borderLeft:'1px solid #fff',padding:'20px 55px',borderRight : route.id === 4 ? '1px solid #fff' : '0'}}
    href={route.navigateTo}>
        {route.name}
        </a>
  )
}

export default HeaderRouteItem